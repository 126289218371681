<template>

    <b-card>

      <b-row>

        
          <b-col
                      cols="12"
                          sm="12"
                          md="12"
                          lg="12"
                          xl="12"
                    v-if="loading"
                    align="center"
                  >
  
                  <b-row>
  
                    <b-col cols="12" sm="4" md="4" lg="4" xl="4">
  
                      <b-skeleton width="100%"  height="10px"></b-skeleton>
  
  
                    </b-col>

  
                    <b-col cols="12" sm="8" md="8" lg="8" xl="8">  </b-col>



                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        
                        <b-skeleton width="100%"  height="350px"></b-skeleton>


                      </b-col>

                    
                      <b-col cols="12" sm="4" md="4" lg="4" xl="4">
  
                        <b-skeleton width="100%"  height="10px" style="margin-bottom:10px"></b-skeleton>
                  

                      </b-col>

                      <b-col cols="12" sm="8" md="8" lg="8" xl="8">
                          
                       

                        </b-col>

                        
                      <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                          
                        <b-skeleton width="100%"  height="40px" style="margin-bottom:10px"></b-skeleton>
                  

                        </b-col>

                        <b-col cols="12" sm="3" md="3" lg="3" xl="3">

                        </b-col>
  
                 
  
                        <b-col cols="12" sm="6" md="6" lg="6" xl="6" style="margin-top: 15px;">
  
                        <b-skeleton width="100%"  height="10px"></b-skeleton>
  
                        <table width="100%">
                                    <tr>
                                      <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
                                    <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
                                    <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
                                    <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
                                    <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
                                    <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
                                    </tr>
                                  </table>
  
                        </b-col>

                        <b-col cols="12" sm="3" md="3" lg="3" xl="3"></b-col>
  
                        <b-col
                                cols="4"
                                    sm="4"
                                    md="4"
                                    lg="4"
                                    xl="4"
                                  > </b-col>
  
  
                                <b-col
                                cols="4"
                                    sm="4"
                                    md="4"
                                    lg="4"
                                    xl="4"
                                  >
  
                                  <b-skeleton width="100%"  height="40px"></b-skeleton>
                                
                                </b-col>
  
  
                                <b-col
                                cols="4"
                                    sm="4"
                                    md="4"
                                    lg="4"
                                    xl="4"
                                  > </b-col>
  
  
                    
  
  
                  </b-row>
  
                  </b-col>
  
            


                      <b-col
                      v-else-if="isNegocioFisico"
                        cols="12"
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                        align="center"
                        style="padding: 0;"
                      >



                      <b-form autocomplete="off" @submit.prevent="onSubmit" >

                                    <b-row >

                                    <b-col  cols="12" sm="12" md="12" lg="12" xl="12">

                                        <p   class="textLabel">
                                                Indique la ubicación de la empresa (*):
                                                </p>

                                            
                                                    <gmap-map
                                        @click="mark"
                                                        :center="center"
                                                        :zoom="16"
                                                        map-type-id="terrain"
                                                        style="width: 100%; height: 350px"
                                                        :options="{
                                                            zoomControl: true,
                                                            mapTypeControl: true,
                                                            scaleControl: true,
                                                            streetViewControl: true,
                                                            rotateControl: true,
                                                            fullscreenControl: true,
                                                            disableDefaultUi: true
                                                            }"
                                                    >
                                                        
                                                    <gmap-marker
                                                            :key="index"
                                                            v-for="(m, index) in markers"
                                                            :position="m.position"
                                                            :clickable="true"
                                                            :draggable="true"
                                                            @click="center=m.position"
                                                            @dragend="handleMarkerDrag"
                                                            
                                                        />
                                                        
                                            </gmap-map>

                                              

                                    

                                        </b-col>

                                            <b-col
                                            cols="12"
                                                sm="12"
                                                md="12"
                                                lg="12"
                                                xl="12"
                                                style="margin-bottom: 10px;margin-top:15px"
                                            >

                                                <p   class="textLabel">
                                                Dirección de la empresa (*):
                                                </p>



                                                <b-form-input
                                                v-model="address"
                                                placeholder="Ingrese la dirección del negocio"
                                                required
                                                :readonly="isActive"
                                                type="text"
                                                />



                                            </b-col>

                                            <b-col
                                                sm="12"
                                                md="12"
                                                lg="12"
                                                xl="12"
                                            >

                                                <p
                                                class="textLabel"
                                                style="text-align: center;margin-bottom: 10px;"
                                                >
                                                Ingrese su código PIN (*): <feather-icon
                                                    icon="HelpCircleIcon"
                                                    size="16"
                                                    v-b-popover.hover.bottom.html="'Si aún no tiene registrado un código PIN o se le olvidó cual era, puede dirigirse a <b>Mi Perfil > Código PIN de seguridad </b> para registrarlo o modificarlo'"
                                                    title="Código PIN de seguridad"
                                                    style="margin-right: 5px;cursor:pointer;"
                                                    /> </p>

                                                <PincodeInput
                                                v-model="pin"
                                                placeholder="-"
                                                :length="6"
                                                :secure="true"
                                                required
                                                :autofocus="false"
                                                :readonly="isActive"
                                                />

                                            </b-col>


                                            

                                            
                                            <b-col
                                                sm="12"
                                                md="12"
                                                lg="12"
                                                xl="12"
                                                align="center"
                                            >

                                                <b-button
                                                id="btn_actualizar"
                                                ref="btn_actualizar"
                                                type="submit"
                                                variant="primary"
                                                :disabled="isActive"
                                                >

                                                Actualizar ubicación

                                                </b-button>

                                            </b-col>

                                            




                                    </b-row>

                                    </b-form>


                    

                      </b-col>

                      <b-col
                      v-else-if="notSupport"
                        cols="12"
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                        align="center"
                        style="padding: 0;"
                      >



                               
                      <svg width="100" height="100" viewBox="-20 0 190 190"  xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M38.155 140.475 48.988 62.1108l43.881 4.946 18.568 23.955-8.041 57.1092L38.155 140.475zM84.013 94.0018 88.827 71.8068l-34.781-3.5-9.854 67.1502L98.335 142.084l6.542-45.2752-20.864-2.807zM59.771 123.595C59.394 123.099 56.05 120.299 55.421 119.433 64.32 109.522 86.05 109.645 92.085 122.757 91.08 123.128 86.59 125.072 85.71 125.567 83.192 118.25 68.445 115.942 59.771 123.595zM76.503 96.4988 72.837 99.2588l-5.515-6.642L59.815 96.6468l-3.029-5.069L63.615 88.1508l-4.526-5.452L64.589 79.0188 68.979 85.4578 76.798 81.5328 79.154 86.2638l-7.047 3.783 4.396 6.452z" fill="#4a4a4a"/></svg>
               
                              <b-alert
                        variant="primary"
                        show
                        style="margin-top:0px;"
                        >
                        <div class="alert-body">
                                
                        
                            <p style="text-align:center;font-size:14px">Tu navegador no soporta la API de geolicación. Por favor intente con el navegador Chrome, Brave, Mozilla u otro.</p>
  
                        </div>
                        </b-alert>     

                    

                      </b-col>

                      <b-col
                      v-else
                        cols="12"
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                        align="center"
                        style="padding: 0;"
                      >



                      <svg width="150" height="150" viewBox="-20 0 190 190"  xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M38.155 140.475 48.988 62.1108l43.881 4.946 18.568 23.955-8.041 57.1092L38.155 140.475zM84.013 94.0018 88.827 71.8068l-34.781-3.5-9.854 67.1502L98.335 142.084l6.542-45.2752-20.864-2.807zM59.771 123.595C59.394 123.099 56.05 120.299 55.421 119.433 64.32 109.522 86.05 109.645 92.085 122.757 91.08 123.128 86.59 125.072 85.71 125.567 83.192 118.25 68.445 115.942 59.771 123.595zM76.503 96.4988 72.837 99.2588l-5.515-6.642L59.815 96.6468l-3.029-5.069L63.615 88.1508l-4.526-5.452L64.589 79.0188 68.979 85.4578 76.798 81.5328 79.154 86.2638l-7.047 3.783 4.396 6.452z" fill="#4a4a4a"/></svg>
                      <b-alert
                        variant="primary"
                        show
                        style="margin-top:0px;"
                        >
                        <div class="alert-body">
                                
                        
                            <p style="text-align:center;font-size:14px">Esta funcionalidad no se encuentra disponible debido a que tu negocio actualmente es de tipo virtual.</p>
  
                        </div>
                        </b-alert>    
                        
                        <p style="font-weight: bold; text-align: center;margin-top: 15px;">¿Desea cambiar el tipo de su negocio de virtual a físico?</p>
                      
                     
                        <b-button
                          @click="changeType"
                                                variant="primary"
                                             
                                                >

                                                Actualizar tipo de negocio

                                                </b-button>

                    

                      </b-col>


          </b-row>

                  
 </b-card>
      
         
      
      </template>
      
      <script>
      import {
        BButton, BRow, VBPopover, BCol, BCard,BFormInput, BForm, BSkeleton, BAlert
      } from 'bootstrap-vue'
      
      import PincodeInput from 'vue-pincode-input'
      
      export default {
        components: {
          BForm,
          BFormInput,
          BButton,
          BRow,
          BCol,
          BCard,
          BSkeleton,
          PincodeInput,
          BAlert
        },
        directives: {
          'b-popover': VBPopover,
        },
        props: ['userId','tokenAuth', 'idLocal'],
        data() {
      
      
          return {
            pin:"",
            loading:true,
            isNegocioFisico:false,
            Ubication:[],
            address:"",
            center: {},
            markers: [],
            geocoder:null,
            plusCode:"",
            isActive:false,
            notSupport:false,
       
          }
        },
        computed: {
      
        },
        watch: {
      
        },
        mounted() {

          this.loadUbicacion();

      
        },
        methods: {

            changeType(){
                this.$toast.error("Funcionalidad no disponible por el momento", {
          position: 'top-right',
          timeout: 3010,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.4,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: true,
          rtl: false,
        })

            },

      onSubmit(event) {
        event.preventDefault();



        this.isActive=true;
      
      const local_json = {
           userId: this.userId,  pin: this.pin, idLocal: this.idLocal
          }


          const ubicacion_json = {
            address: this.address,latitude: this.center.lat,longitude: this.center.lng, plusCode:this.plusCode
          }



        const local_string = JSON.stringify(local_json)
        const ubicacion_string = JSON.stringify(ubicacion_json)


        const dataEncripted1 = this.$encryptBackoffice.encrypt(local_string)
        const dataEncripted2 = this.$encryptBackoffice.encrypt(ubicacion_string)


        document.getElementById('btn_actualizar').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Actualizando'

     
      this.$https.post('/locals/updateUbicacion/', { tokenAuth: this.tokenAuth, dataEncripted1: dataEncripted1, dataEncripted2:dataEncripted2 }).then(response => {
        document.getElementById('btn_actualizar').innerHTML = 'Actualizar ubicación';
        this.isActive=false;

  
    
      if (response.data.code == 200) {

  

        this.$toast.success(response.data.message, {
          position: 'top-right',
          timeout: 3010,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.4,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: true,
          rtl: false,
        });

        this.pin="";


 

      } else {



      if (response.data.code == 401) {

        this.$toast.error(response.data.message, {
          position: 'top-right',
          timeout: 3010,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.4,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: true,
          rtl: false,
        })

        localStorage.removeItem('userData')


        this.$router.push({ name: 'auth-login' })
      } else {

        if(response.data.code == 503){

            this.pin="";
            this.$toast.error(response.data.message, {
          position: 'top-right',
          timeout: 3010,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.4,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: true,
          rtl: false,
        })


        }
        
      }
      }
      }).catch(error => {
        this.$toast.error("Ocurrio un error " + error, {
          position: 'top-right',
          timeout: 3010,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.4,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: true,
          rtl: false,
        })
      })

      
  
       
      },
      instanceMap(){
      this.geocoder = new google.maps.Geocoder();

    },
    handleMarkerDrag(e) {
     

     this.center = {
           lat: Number(e.latLng.lat()),
           lng: Number(e.latLng.lng())
         };



           this.geocoder
             .geocode({ location: this.center })
             .then((response) => {


               if (response.results[0]) {

                 this.plusCode=response.results[0].formatted_address

             
               }
             })
             .catch((e) => console.log("Geocoder failed due to: " + e));



    
   },

   mark(event) {



         this.center = {
           lat: Number(event.latLng.lat()),
           lng: Number(event.latLng.lng())
         };

         this.markers =
           [
               {
                   position: {
                       lat: Number(event.latLng.lat()), lng: Number(event.latLng.lng())
                   },
               }, // Along list of clusters
           ]


        

           this.geocoder
             .geocode({ location: this.center })
             .then((response) => {


               if (response.results[0]) {

                 this.plusCode=response.results[0].formatted_address

             
               }
             })
             .catch((e) => console.log("Geocoder failed due to: " + e));



   },

    cargarScript(){

            // As an instance method inside a component
            this.$loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyC_a_0lKNN1bFwUzAamxrJE8GEdAU6iEnE")
            .then(() => {
            // Script is loaded, do something
            this.instanceMap()
            })
            .catch(() => {
            // Failed to fetch script
            this.cargarScript();
            });

            },

          loadUbicacion(){

            this.loading = true;         
              const userId_json = { userId: this.userId, idLocal: this.idLocal }
              const user_string = JSON.stringify(userId_json)

              const dataEncripted = this.$encryptBackoffice.encrypt(user_string);


      
  

              this.$https.post('/locals/getUbicacion/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {
           
                if (response.data.code == 200) {
                
                  this.loading = false;
              

                  this.typeLocal =response.data.typeLocal;


                  if(this.typeLocal === "fisico"){

                    this.cargarScript();

                    if(!("geolocation" in navigator)) {

                            this.$toast.error("La geolocalización no está soportado para este navegador", {
                                position: 'top-right',
                                timeout: 3010,
                                closeOnClick: true,
                                pauseOnFocusLoss: false,
                                pauseOnHover: true,
                                draggable: true,
                                draggablePercent: 0.4,
                                showCloseButtonOnHover: false,
                                hideProgressBar: true,
                                closeButton: 'button',
                                icon: true,
                                rtl: false,
                            });

                            this.isNegocioFisico=false;
                            this.notSupport=true;

                           
                    }else{

                        this.isNegocioFisico=true;


                        this.Ubication= response.data.Ubication;
                        this.address=response.data.address;

                    

                        
                            this.center = {
                                lat: Number(this.Ubication._latitude),
                                lng: Number(this.Ubication._longitude)
                            };

                            this.markers =
                                [
                                    {
                                        position: {
                                            lat:  Number(this.Ubication._latitude), lng: Number(this.Ubication._longitude)
                                        },
                                    }, // Along list of clusters
                                ]


                        
                                this.geocoder
                                    .geocode({ location: this.center })
                                    .then((response) => {

                            
                                        if (response.results[0]) {

                                    
                                        this.plusCode=response.results[0].formatted_address
                                    
                                    
                                        }
                                    })
                                    .catch((e) => console.log("Geocoder failed due to: " + e));


                    }

                  



                        }else{

                            this.isNegocioFisico=false;

                        }


           

                } else {


                  if (response.data.code == 401) {

                    this.$toast.error(response.data.message, {
                    position: 'top-right',
                    timeout: 3010,
                    closeOnClick: true,
                    pauseOnFocusLoss: false,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.4,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: 'button',
                    icon: true,
                    rtl: false,
                  })
                    
                    localStorage.removeItem('userData')

                    

                    // Redirect to login page
                    this.$router.push({ name: 'auth-login' })
                  } else {

                    if( response.data.code === 404){
                      this.$eventBus.$emit('activeHome');
                         this.$eventBus.$emit('reiniciarAllNegocio');
                    }else{
                      this.loadUbicacion();
                    }

                  }
                }
              }).catch(error => {

                this.loadUbicacion();
                
              })



          },
      
      
        },
      }
      </script>
      
      <style lang="scss">
      
      
      
      </style>
      